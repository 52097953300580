.terms-container {
    
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 25px;
    padding-bottom: 25px;
    .last-el{
        margin:0px 0px 20px 0px;
    }
}

.content-text {
    font-size: 14px;
    font-family: "Roboto-Regular";
}

.content-title {
    font-size: 14px;
    font-family: "Roboto-Medium";
}

.terms-title {
    font-size: 26px;
    font-family: "Roboto-Medium";
}

.terms-subtitle {
    font-size: 16px;
    font-family: "Roboto-Medium";
}

.top-bar-logo {
  top: 50%;
  transform: translateY(-50%);
  position: relative;
  margin-left: 10px;
  @media (max-width: 599px) {
    width: 25vw;
    max-width: 150px;
  }
}
.right-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .user-name {
    color: #ffffff;
    font-family: "Roboto-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    max-width: 70px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    // padding-left: 20px;
  }
  .nav {
    .nav-link {
      padding: 7px 10px !important;
      color: #a08687;
      font-family: Roboto-Medium;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      line-height: 18px;
      cursor: pointer;
    }
  }
  .selected-nav-item {
    .nav-link {
      span {
        border-bottom: 2px solid #ffffff;
        color: #ffffff;
      }
    }
  }
  .profile-avatar {
    width: 30px !important;
    height: 30px !important;
    background: #f1f1f1;
    margin-left: 10px;
    border-radius: 50%;
    cursor: pointer;
    @media (max-width: 599px) {
      margin-left: 0px;
    }
  }
  //bell
  .notification-badge {
    font-family: "Roboto-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 11px;
    color: #ffffff;
  }

  .dropdown {
    .notification-toggle-btn {
      outline: none;
      border: none;
      box-shadow: none;
      background-color: #141414;
      padding-right: 20px;
      @media (max-width: 599px) {
        padding-right: 10px;
      }
    }
    .notification-dropdown-menu {
      width: 451px;
      height: calc(100vh - 55px);
      // max-height: 700px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      border: none;
      padding: 0px;
      @media (max-width: 599px) {
        width: 95vw;
        height: calc(100vh - 200px);
      }
    }
  }
}
.search-container {
  display: flex;
  align-items: center;
  @media (max-width: 599px) {
    flex-grow: 1;
  }
  .MuiInputBase-root,
  .MuiOutlinedInput-root,
  .MuiInputBase-formControl,
  .MuiInputBase-adornedStart,
  .MuiOutlinedInput-adornedStart {
    width: 346px;
    height: 30px;
    margin-left: 27px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    font-size: 14px;
    align-items: center;
    padding: 3px 3px 3px 7px !important;
    color: #c1c1c1;
    background: #ffffff;
    // border: 1px solid rgba(187, 200, 216, 0.3);
    box-shadow: 0px 6px 12px rgba(44, 44, 114, 0.08);
    border-radius: 5px;
    @media (max-width: 599px) {
      width: 100%;
      margin-left: 5px;
    }
  }
  .MuiOutlinedInput-root,
  .MuiOutlinedInput-notchedOutline {
    border-color: #141414 !important;
    margin-top: 4px;
  }
  .MuiButtonBase-root,
  .MuiIconButton-root {
    padding: 0 !important;
  }
  .Mui-focused {
    // display: none;
  }
  #outlined-adornment-weight {
    padding: 0 !important;
  }
  .outlined-adornment-weight {
    display: none !important;
  }

  #search-icon {
    position: relative;
    vertical-align: middle;
    // cursor: pointer;
  }

  // img {
  //   position: absolute;
  //   left: 405px;
  // }
  .form-control {
    width: 346px;
    margin-left: 27px;
    // font-style: normal;
    // font-weight: normal;
    // font-size: 14px;
    // line-height: 22px;
    // font-size: 14px;
    // line-height: 22px;
    // line-height: 22px;
    align-items: center;
    // letter-spacing: -0.408px;
    padding: 3px 3px 3px 7px !important;
    color: #c1c1c1;
    // position: absolute;
    background: #ffffff;
    border: 1px solid rgba(187, 200, 216, 0.3);
    box-shadow: 0px 6px 12px rgba(44, 44, 114, 0.08);
    border-radius: 5px;
  }

  .search-text {
    font-style: normal;
    font-weight: normal;
    font-family: "Roboto-Medium";
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.408px;
    border: none !important;
    width: 100% !important;
    border-width: 0px !important;
  }

  :focus-visible {
    // outline: #ffffff auto 0px;
    outline: none;
  }
}

.filter {
  margin-left: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.filter-container {
  .MuiPopover-paper {
    top: 45px !important;
    overflow-y: unset !important;
    // height: 635px !important;
    @media (max-width: 599px) {
      height: auto !important;
    }
  }  
  .MuiMenu-paper {
    width: 385px !important;
    .MuiList-padding {
      padding-right: 0px !important;
    }
  }
}
.search-container {
  .MuiPopover-paper {
    top: 45px !important;
  }
}

.MuiPopover-paper {
  outline: 0;
  position: absolute;
  max-width: calc(100% - 32px);
  min-width: 16px;
  max-height: calc(100% - 32px);
  min-height: 16px;
  overflow-x: hidden;
  overflow-y: auto;
  // top: 45px !important;
}

.filterDD {
  margin-left: 10px;
  .btn-secondary,
  .btn-secondary:hover,
  .btn-secondary:focus,
  .btn-secondary:active,
  .btn-secondary.dropdown-toggle {
    color: #141414 !important;
    background-color: #141414 !important;
    border-color: #141414 !important;
    box-shadow: none !important;
  }

  .btn {
    margin: 0 !important;
    padding: 0 !important;
  }
  .dropdown-header {
    font-size: 16px;
    font-family: "Roboto-Regular";
    color: #333333;
  }
}

.MuiListItem-root .MuiListItem-gutters {
  padding: 0px !important;
  .MuiCheckbox-root
    .MuiCheckbox-colorSecondary
    .MuiIconButton-colorSecondary
    .MuiIconButton-edgeStart {
    padding: 0px !important;
    margin: 0px !important;
  }
}

.role-dropdown {
  width: 100%;
  .dd-item {
    font-size: 13px !important;
    font-family: "Roboto-Medium" !important;
  }

  .MuiSelect-iconOutlined {
    right: 20px !important;
    top: 13px;
  }
  .MuiPopover-paper {
    top: 140px !important;
    width: fit-content !important;
  }
}

.role-dd {
  .MuiMenu-paper {
  }
  .MuiPopover-paper {
    top: 140px !important;
    width: fit-content !important;
  }
}

.MuiCheckbox-colorSecondary .Mui-checked {
  color: #00b5fd !important;
}

.sub-heading-text {
  width: 100%;
  font-family: "Roboto-Medium" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #333333 !important;
  margin-bottom: 12px !important;
}

.search-category {
  .MuiFilledInput-root {
    height: 40;
    border-radius: 50%;
    // background: #f7f7f7;
    border: 1px solid #dbdbdb;
  }
}
.no-search-data-found {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 215px;
  margin-bottom: 215px;
}
.category-search {
  // height: 415px;
  display: flex;
  flex-direction: column;
  background-color: transparent !important;
  .MuiInputLabel-formControl {
    background-color: transparent !important;
  }
  @media (max-width: 599px) {
    height: 268px;
  }
}

.MuiListItemText-root {
  font-family: "Roboto-Regular";
  font-style: normal;
  font-size: 13px;
  color: #393939;
}

.category-search .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-radius: 60px;
  border: 1px solid #dbdbdb !important;
}

.button-item {
  height: 75px;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  // height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
  .MuiButton-text {
    padding: 0px !important;
    border-radius: 60px !important;
  }
}

.button-item .MuiListItem-root {
  padding-left: 0 !important;
}

.btn-class .MuiButton-label {
  background: #ffffff;
  border: 1px solid #00b5fd !important;
  // box-sizing: border-box;
  border-radius: 60px !important;
  width: 160px;
  height: 40px;
  color: #00b5fd;
  font-family: "Roboto-Medium";
  font-weight: 500;
  font-size: 14px;
  @media (max-width: 599px) {
    width: 100px;
  }
}
.apple-btn-disabled .MuiButton-label {
  background: #e0e0e0;
  // border: 1px solid #00b5fd !important;
  // box-sizing: border-box;
  border-radius: 60px !important;
  width: 160px;
  height: 40px;
  color: #757575;
  font-family: "Roboto-Medium";
  font-weight: 500;
  font-size: 14px;
}

.apply-class {
  background: #00b5fd !important;
  // box-sizing: border-box;
  border-radius: 60px !important;
  width: 160px !important;
  height: 40px !important;
  color: #ffffff !important;
  font-family: "Roboto-Regular" !important;
  font-weight: 500 !important;
  font-size: 14px !important; @media (max-width: 599px) {
    width: 100px !important;
  }
}
.apply-class-disable {
  background-color: #e0e0e0 !important;
  color: #757575 !important;
  border-radius: 60px !important;
  width: 160px !important;
  height: 40px !important;
  font-family: "Roboto-Regular" !important;
  font-weight: 500 !important;
  font-size: 14px !important; 
  @media (max-width: 599px) {
    width: 100px !important;
  }
}

.MuiTypography-body2 {
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  font-size: 13px !important;
  font-style: normal !important;
  color: #393939 !important;
  font-family: "Roboto-Regular" !important;
}

.MuiListItemText-primary {
  font-family: "Roboto-Regular";
  font-size: 13px !important;
}

.checkbox-title {
  font-weight: 400 !important;
  font-style: normal !important;
  color: #393939 !important;
  font-weight: 400 !important;
  font-family: "Roboto-Regular" !important;
}

.btn .badge {
  top: 3px !important;
  left: 22px !important;
}

.chip-list {
  margin-top: 11px;
  display: flex;
  width: 100%;
  // flex-wrap: wrap;
  // overflow: auto;
  overflow-x: hidden;
}

.chip-list-item {
  padding-left: 7px;
  padding-right: 7px;
  background-color: #f7f7f7;
  border: 1px solid #ebebeb;
  margin-right: 5px;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  @media (max-width: 599px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.chip-list-remove {
  height: 6.4px;
  width: 6.4px;
  padding: 0px !important;
}

.chip-list-item-text {
  color: #828282;
  font-size: 10px;
  font-family: "Roboto-Regular";
  font-style: normal;
  margin-bottom: 0px;
  margin-right: 5px;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #00b5fd !important;
}

.MuiCheckbox-colorPrimary {
  color: #00b5fd;
}

.MuiBadge-colorPrimary {
  color: #fff;
  background-color: #00b5fd !important;
}

.custom-checkbox-root .MuiSvgIcon-root {
  width: 36px;
  height: 36px;
}

.clear-all-text {
  font-family: "Roboto-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #00b5fd;
  margin-right: 17px;
  cursor: pointer;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search-menu-title {
    font-family: "Roboto-Medium";
    font-size: 16px;
    color: "#333333";
    padding: 15px;
  }
  .filter_title {
    font-family: "Roboto-Medium";
    font-size: 16px;
    color: "#333333";
    padding: 15px;
  }
}
.MuiMenuItem-root {
  height: fit-content;
}

.filter-search-bar-container {
  display: flex;
  width: 100%;
  background-color: #f7f7f7;
  border-radius: 15px;
  .MuiSvgIcon-root {
    color: #c4c4c4;
  }
}

// .MuiInputLabel-formControl {
//   top: -9px !important;
//   font-size: 13px !important;
//   background: #ffffff !important;
//   font-size: 12px !important;
//   // color: #a8a8bc !important;
//   padding: 0px 3px !important;
//   font-family: "Roboto-Regular" !important;
// }

// .MuiCheckbox-root {
//   // color: rgba(0, 0, 0, 0.54);
//   color: #E0E0E0;
//   width: 54px;
//   height: 24px;
//   margin-bottom: 10px;
// }
.option_title {
  // margin-left: 12px;
  font-size: 14px;
  font-family: "Roboto-Medium";
  color: #000000;
}
.option_role {
  padding-left: 6px;
  font-size: 11px;
  font-family: "Roboto-Regular";
  color: #828282;
}
.option_des {
  font-size: 13px;
  font-family: "Roboto-medium";
  color: #828282;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
}

.option_des_search {
  font-size: 13px;
  font-family: "Roboto-medium";
  color: #828282;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 55vw;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}
.category-search::-webkit-scrollbar {
  width: 5px !important;
  background-color: #ebebee !important;
}

.filter-dropdown-custom {
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  position: absolute;
  top: 52px;
  width: 346px;
  max-height: 616px;
  overflow-y: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.search-dropdown-custom {
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  border-radius: 5px 5px 0px 0px;
  position: absolute;
  top: 45px;
  width: 346px;
  max-height: 616px;
  margin-left: 27px;
  overflow-y: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  @media (max-width: 599px) {
    width: 80vw;
    margin-left: 5px;
  }
}
.search-dropdown-custom::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.search-dropdown {
  width: 100%;
  height: 100%;
  .MuiMenu-paper {
    width: 346px;
  }
  .MuiList-root,
  .MuiMenu-list,
  .MuiList-padding {
    padding-right: 0px !important;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .scrollbar-hidden::-webkit-scrollbar {
    display: none !important;
  }

  /* Hide scrollbar for IE, Edge add Firefox */
  .scrollbar-hidden {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important; /* Firefox */
  }
}
.view_all_style {
  color: #00b5fd;
  font-size: 14px;
  font-family: "Roboto-Medium";
  float: right;
  text-decoration: none;
}
.section-title {
  color: #333333;
  font-size: 16px;
  font-family: "Roboto-Medium";
}
.search-avatar-div {
  height: 40px;
  width: 40px;
  min-width: 40px;
  background: #f1f1f1;
  border-radius: 40px;
  overflow: hidden;
  margin-right: 12px;
}
.search-avatar-img {
  height: auto;
  width: auto;
  max-height: 40px;
  max-width: 40px;
  // position: relative;
}

.list-height {
  width: 100%;
  background-color: transparent !important;
  height: 36vh !important;
  .MuiListItem-root {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.filter-width {
  width: 385px;
}

@import "../../global.scss";
.DrawerComponent-drawerOpen-67,
.DrawerComponent-drawerOpen-9,
.DrawerComponent-drawerOpen-22 {
  background-color: #fcfcfe !important;
}
.drawer-profile {
  background-color: #f9f9f9;
  border-bottom: 1px solid #ebebeb;
  cursor: pointer;
}

.drawer-profile-img {
  height: 60px;
  width: 60px;
  object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: relative;
}

.drawer-profile-img-div {
  height: 60px;
  width: 60px;
  min-width: 60px;
  background: $COLOR_F1F1F1;
  border-radius: 30px;
  overflow: hidden;
}

.drawer-profile-name {
  color: $textColor;
  font-family: Roboto-Bold;
  font-size: $font16;
  font-weight: $font-weight-medium;
  line-height: $font18;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 154px;
}

.drawer-profile-desi {
  color: $textColorLight;
  font-family: "Roboto-Bold";
  font-size: $font12;
  font-weight: $font-weight-medium;
  line-height: $font18;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 154px;
  margin-top: 5px;
}

.drawer-profile-name-align {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}

.logout-text {
  .MuiTypography-root {
    color: #f15642 !important;
  }
}
.MuiList-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.MuiListItemText-primary {
  color: $textColorExtraLight !important;
  font-family: "Roboto-Medium" !important;
  font-size: $font13 !important;
  font-weight: 500 !important;
  font-style: normal !important;
  letter-spacing: normal !important;
}

.selected-menu {
  background-color: #eff8fe !important;
  svg {
    stroke: #00b5fd;
  }
  .MuiListItemText-root {
    .MuiTypography-root {
      color: #00b5fd !important;
      font-family: "Roboto-Bold" !important;
      font-size: $font13;
      font-weight: 500;
    }
  }
}

// .MuiButtonBase-root
.MuiListItem-root {
  // padding-left: 25px !important;
  cursor: pointer;
  &:hover {
    background-color: transparent !important;
  }
}

.pad {
  padding-left: 25px !important;
}

.MuiListItemIcon-root {
  min-width: 46px !important;
}

.MuiList-root {
  // height: calc(100vh - 158px) !important;
  width: 100% !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.list-button-wrapper {
  display: flex !important;
  width: 100% !important;
}
.menu-list-custom {
  height: 100% !important;
  width: 100% !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  background-color: #fcfcfe !important;
  li {
    height: 48px !important;
  }
  li:hover {
    background-color: #eff8fe !important;
  }
}
.version-text {
  color: $textColorExtraLight !important;
  font-family: "Roboto-Medium" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-style: normal !important;
  letter-spacing: normal !important;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  height: 60%;
}

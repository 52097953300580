// Override default variables before the import
$body-bg: #000;
$primary: #00b5fd;
$primaryRippleColor: #0082FB;
$lightRippleColor: #dae0e5;
$borderColor: #808080;
$danger: #b00020;
$textColor: #000000;
$textColor2: #1a1a1a;
$disabledText: #919191;
$itemText: #282828;
$lightTextColor: #808080;
$anchorColor: #313131;
$whiteColor: #ffffff;
$tableStripeColor: #f7f3ff;
$tableHoverColor: #ccf0ff;
$rejectColor: #ff0000;
$pendingColor: #ffa500;
$approveColor: #17b82c;
$deleteBTNColor: $rejectColor;
$page-bg: #EEEDF1;
$sidebar-bg: #fcfcfe;
$active-menu: #f2e8fe;
$menu-hover: #f3edfa;
$menu-item-text:#393939;
$highlighter:#00b5fd;

$pdf-color: #f15642;
$fileBorderColor: #eeeeee;
$file-bg: #f8f8f8;
$bar-color:#f6f6f6;

/* Font Family */
@font-face {
  font-family: "Roboto-Medium";
  src: local("Roboto-Medium"),
    url(../assets/fonts/Roboto-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto-Regular"),
    url(../assets/fonts/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto-Bold";
  src: local("Roboto-Bold"),
    url(../assets/fonts/Roboto-Bold.ttf) format("truetype");
}

@font-face {
  font-family: 'SFProText-Semibold';
  src: local('SFProText-Semibold'), url(../assets/fonts/SFProText-Semibold.ttf) format('truetype');
}

@mixin Roboto-Regular {
  font-family: "Roboto-Regular", arial, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin Roboto-Medium {
  font-family: "Roboto-Medium", arial, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin Roboto-Bold {
  font-family: "Roboto-Bold", arial, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin SFProText-Semibold {
  font-family: "SFProText-Semibold", arial, sans-serif;
  font-weight: normal;
  font-style: normal;
}

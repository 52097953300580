/*************** Common color variables **************/
$whiteColor:                           #ffffff;    
$blackColor:                           #000000;
$labelColor:                           #a8a8bc;
$headingColor:                         #272730;
$primaryColor:                         #00B5FD;
$dangerColor:                          #f44336;
$COLOR_FF5151:                         #FF5151;
$borderColor:                          #D9D9D9;
$headerBgColor:                        #f9f9f9;
$COLOR_1C0447:                         #1C0447;
$COLOR_F1F1F1:                         #F1F1F1;
$emptyActionBtnColor:                  #a58b89;
$redBtnColor:                          #f15642;
$disableColor:                         #E0E0E0;
$textColor:                            #333333; 
$textColorLight:                       #898A8D;  
$textColorExtraLight:                  #A7A7A7;        

@import '../theme/common-chat.scss';

.cropper-view-wrapper{
    // display: flex;
    // justify-content: center;
    height: 100%;
    .header-view{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        .left-header-view{
            .close-icon{
                cursor: pointer;
            }
        }
        .right-header-view{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .rotate-icon{
                cursor: pointer;
                margin-left: 17px;
            }
            .dropdown{
                button{
                    outline: none;
                    border: none;
                    box-shadow: none;
                    background-color: $whiteColor;
                    color: #333333;
                    font-family: 'Roboto-Medium';
                    font-size: $font18;
                    padding-right: 0px;
                    // background-image: url(../assets/images/ic_dropdown.svg);
                    img{
                        margin-left: 3px;
                    }
                    &:focus{
                        border: none;
                    }
                }
                .dropdown-menu {
                    padding: 20px 15px;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
                    border-radius: 5px;
                    border: 1px solid #E0E0E0;
                    .dropdown-header{
                        color: #333333;
                        font-family: 'Roboto-Medium';
                        font-size: $font14;
                        line-height: 16px;
                        padding: 0;
                        
                    }
                    .dropdown-item{
                        padding: 0px;
                        padding-top: 15px;
                        font-family: 'Roboto-Medium';
                        font-size: $font13;
                        line-height: 15px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                    .selected-item{
                        color: #00B5FD !important;
                    }
                }
            }
        }
    }
    .crop-container{
        height: calc(100% - 59px);
        display: flex;
        align-items: center;
        justify-content: center;
        .ReactCrop{
            height: 100%;
            div {
                height: 100% ;
            }
            img {
                height: 100%;
                object-fit: contain;
            }
            .ReactCrop__crop-selection{
                border-image: none;
                border: 3px solid $whiteColor;
            }
            .ReactCrop__drag-elements{
                background-image: url(../assets/images/cropper/ic_cropper1.svg), url(../assets/images/cropper/ic_cropper2.svg), url(../assets/images/cropper/ic_cropper3.svg), url(../assets/images/cropper/ic_cropper4.svg);
                background-position: 3% 3%, 97% 3%, 3% 97%, 97% 97%;
                background-repeat: no-repeat;
            }
        }
    }
    
}
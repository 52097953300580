@import "../theme/common-chat.scss";
.chat-wrapper {
    height: 100%;
    width: 100%;
    .chat-sub-wrapper{
        display: flex;
        height: 100%;
        width: 100%;
    }
    .chat-list-section,
    .chat-section {
        border: 1px solid $borderColor;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        height: 100%;
        .heading-container {
            background-color: $headerBgColor;
            height: 51px;
            color: #333333;
            font-family: "Roboto-Medium";
            font-weight: 500;
            font-size: $font15;
            text-align: left;
            padding-left: 17px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid $borderColor;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 5px 5px 0px 0px;
            .three-dot-icon {
                padding-right: 15px;
                cursor: pointer;
            }
        }
    }
    .chat-list-section {
        flex: 4;
        margin-right: 5px;
        .chat-list-container {
            width: 100%;
            height: calc(100% - 62px);
            .searchbox-wrapper {
                margin: 11px 16px 11px 16px;
                position: relative;
                .search-input {
                    width: 100%;
                    border: 1px solid #DBDBDB;
                    border-radius: 5px;
                    font-family: "Roboto-Regular";
                    font-size: $font13;
                    padding: 10px 10px 10px 36px;
                    line-height: 15px;
                    &:focus-visible {
                        border: 1px solid #808080 !important;
                        box-shadow: none !important;
                        outline: none !important;
                    }
                }
                .search-icon {
                    position: absolute;
                    left: 10px;
                    top: 9px;
                }
            }
            .chat-list {
                width: 100%;
                height: calc(100% - 50px);
                overflow-y: auto;
                .chat-list-item {
                    padding: 0px 16px;
                    padding-top: 5.5px;
                    padding-bottom: 5.5px;
                    &:not(:first-child) {
                        // margin-top: 11px;
                        padding-top: 5.5px;
                        padding-bottom: 5.5px;
                    }
                    display: flex;
                    cursor: pointer;
                    .left-details-section {
                        flex: 8;
                        display: flex;
                        .chat-profile {
                            border-radius: 5px;
                            border: 1px solid #f2f2f2;
                            width: 40px;
                            height: 40px;
                            .profile-img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .recent-chat-section {
                            margin-left: 10px;
                            .chat-person-name {
                                font-family: "Roboto-Regular";
                                font-size: $font14;
                                color: #222222;
                                line-height: 16.41px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                max-width: 120px;
                            }
                            .chat-person-recent-chat {
                                margin-top: 8px;
                                display: flex;
                                .attachment-icon {
                                    margin-right: 5px;
                                }
                                .recent-text {
                                    font-family: "Roboto-Regular";
                                    font-size: $font13;
                                    color: #a3a3a3;
                                    line-height: 15.23px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    max-width: 100px;
                                }
                            }
                        }
                    }
                    .right-details-section {
                        flex: 4;
                        .last-chat-time {
                            font-family: "Roboto-Regular";
                            font-size: $font10;
                            line-height: 12px;
                            color: #a3a3a3;
                            text-align: right;
                            margin-bottom: 5px;
                        }
                        .unread-last-chat-time {
                            color: $primaryColor !important;
                        }
                        .unread-msg-count-badge {
                            background-color: $primaryColor;
                            border-radius: 50%;
                            width: 23px;
                            height: 23px;
                            text-align: center;
                            margin-left: auto;
                            font-family: "Roboto-Medium";
                            font-size: $font12;
                            color: $whiteColor;
                            line-height: 14.32px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
                .selected-item {
                    background-color: #EFF8FE;
                }
            }
        }
        .no-conversation-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: calc(100% - 62px);
            .content-div {
                .no-conversation-text {
                    width: 100%;
                    text-align: center;
                    color: #393939;
                    font-family: "Roboto-Regular";
                    font-size: $font14;
                    img{
                        width: 100%;
                    }
                }
            }
        }
    }
    .chat-section {
        flex: 5;
        margin-left: 5px;
        .heading-container {
            .chat-person-header {
                display: flex;
                justify-content: center;
                align-items: center;
                .chat-person-profile-pic {
                    width: 36px;
                    height: 36px;
                    overflow: hidden;
                    border-radius: 50%;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .chat-person-name {
                    font-family: "Roboto-Medium";
                    font-size: $font15;
                    font-style: normal;
                    line-height: 16px;
                    color: $blackColor;
                    margin-left: 10px;
                }
            }
        }
        .chat-view-container {
            position: relative;
            width: 100%;
            height: calc(100% - 51px);
            .image-cropper-view {
                padding: 10px 17px 17px 17px;
                width: 100%;
                height: calc(100% - 51px);
                overflow-y: auto;
            }
            .chat-msg-group {
                padding: 13px 17px 17px 17px;
                width: 100%;
                height: calc(100% - 51px);
                overflow-y: auto;
                .day-status {
                    font-family: "Roboto-Medium";
                    font-size: $font12;
                    color: #bbc8d8;
                    width: 100%;
                    line-height: 14.06px;
                    text-align: center;
                    padding-bottom: 17px;
                }
                .oppsite-person-content {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    margin-bottom: 5px;
                    .msg-content-outer-wrapper {
                        max-width: 70%;
                        float: left;
                        word-break: break-word; 
                        .msg-text {
                            padding: 13px 15px;
                            background-color: #ebeff3;
                            border-radius: 15px 15px 15px 0px;
                            color: #333333;
                            opacity: 0.6;
                            font-family: "Roboto-Medium";
                            font-size: $font16;
                            line-height: 19px;
                            white-space: pre-line;
                            word-break: break-all;
                            // margin-bottom: 5px;
                        }
                        .msg-attachment-img {
                            max-width: 200px;
                            max-height: 200px;
                            border: 1px solid #ebeff3;
                            border-radius: 10px 10px 10px 10px;
                            overflow: hidden;
                            overflow: hidden;
                            cursor: pointer;
                            img {
                                width: 200px;
                                height: 200px;
                            }
                        }
                        .msg-time {
                            font-family: "Roboto-Medium";
                            font-size: $font12;
                            line-height: 14px;
                            color: #bbc8d8;
                            text-align: right;
                            margin-top: 7px;
                        }
                        .msg-attachment-video {
                            max-width: 200px;
                            min-width: 200px;
                            max-height: 200px;
                            border: 1px solid #ebeff3;
                            border-radius: 10px 10px 10px 10px;
                            overflow: hidden;
                            margin-bottom: 5px;
                            cursor: pointer;
                            .plr {
                                overflow: hidden;
                                background-color: rgba(0, 0, 0, 0.3);
                                max-height: 200px !important;
                                margin-top: 0px !important;
                            }
                        }
                    }
                    .image-msg-view, .video-msg-view{
                        img, .msg-attachment-img {
                            margin-bottom: 0px !important;
                            border: none;
                            border-radius: 3px 3px 0px 0px;
                        }
                        .single-img, .single-video {
                            border-radius: 3px !important;
                        }
                        .oppsite-person-content{
                            .msg-content-outer-wrapper{
                                //max-width: unset !important;
                                width: 100%;
                                max-width: 200px;
                                .msg-text{
                                    border-radius: 0px 0px 15px 0px;
                                }
                            }
                        }
                        .msg-time{
                            min-width: 200px !important;
                        }
                    }
                }
                .current-person-content {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    margin-bottom: 5px;
                    .msg-content-outer-wrapper {
                        max-width: 70%;
                        float: right;
                        word-break: break-word; 
                        .msg-text {
                            padding: 13px 15px;
                            background-color: #00b5fd;
                            border-radius: 15px 15px 0px 15px;
                            color: #ffffff;
                            font-family: "Roboto-Medium";
                            font-size: $font16;
                            line-height: 19px;
                            white-space: pre-line;
                            word-break: break-all;
                            // margin-bottom: 5px;
                        }
                        .msg-attachment-img {
                            max-width: 200px;
                            max-height: 200px;
                            border: 1px solid #00b5fd;
                            border-radius: 10px 10px 10px 10px;
                            overflow: hidden;
                            margin-bottom: 5px;
                            img {
                                width: 200px;
                                height: 200px;
                            }
                        }
                        .msg-time {
                            font-family: "Roboto-Medium";
                            font-size: $font12;
                            line-height: 14px;
                            color: #bbc8d8;
                            text-align: right;
                            margin-top: 7px;
                        }
                        .msg-attachment-video {
                            max-width: 200px;
                            max-height: 200px;
                            border: 1px solid #ebeff3;
                            border-radius: 10px 10px 10px 10px;
                            overflow: hidden;
                            margin-bottom: 5px;
                            cursor: pointer;
                            .plr {
                                overflow: hidden;
                                background-color: rgba(0, 0, 0, 0.3);
                                max-height: 200px !important;
                                margin-top: 0px !important;
                            }
                        }
                    }
                }
                .seperator {
                    width: 100%;
                    height: 15px;
                }
                .image-msg-view, .video-msg-view{
                    img, .msg-attachment-img {
                        margin-bottom: 0px !important;
                        border: none;
                        border-radius: 3px 3px 0px 0px;
                    }
                    .single-img, .single-video {
                        border-radius: 3px !important;
                    }
                    .current-person-content{
                        .msg-content-outer-wrapper{
                            // max-width: unset ;
                            width: 100%;
                            max-width: 200px;
                            .msg-text{
                                border-radius: 0px 0px 0px 15px;
                            }

                        }
                    }
                }
            }
            .shrink-area{
                height: calc(100% - 100px) !important;
                transition: all 0.2s;
            }

            .send-msg-wrapper {
                width: 100%;
                position: absolute;
                background-color: #f1f4f7;
                height: 51px;
                bottom: 0;
                display: inline-flex;
                align-items: center;
                transition: all 0.2s;
                .attachment-wrapper {
                    z-index: 1;
                    padding: 14px 12px 14px 20px;
                    cursor: pointer;
                    height: 52px;
                    display: flex;
                    align-items: center;
                }
                .send-msg-icon-wrapper {
                    z-index: 1;
                    height: 52px;
                    padding: 13px 20px 13px 23px;
                    background-color: #e0e0e0;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                }
                .send-msg-icon-enable {
                    background-color: #00b5fd;
                }
                .text-field-wrapper {
                    z-index: 1;
                    height: 51px;
                    width: 100%;
                    .msg-text-field {
                        width: 100%;
                        resize: none;
                        border: none;
                        overflow: auto;
                        height: 52px;
                        color: $blackColor;
                        font-family: "Roboto-Regular";
                        font-size: $font16;
                        line-height: 18.75px;
                        padding: 16px 16px 5px 0px;
                        caret-color: $primaryColor;
                        unicode-bidi: plaintext;
                        &:focus-visible {
                            border: none;
                            outline: none;
                        }
                        background-color: #f1f4f7;
                        &::placeholder {
                            color: #bdbdbd;
                            line-height: 15.23px;
                            font-size: $font13;
                            padding-top: 2px;
                        }
                    }
                    .padding-20 {
                        padding-left: 20px;
                    }
                }  
            }
            .expand-area{
                height: 100px !important;
                
            }
            .new-conversation-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: calc(100% - 62px);
                .content-div {
                    .new-conversation-text {
                        width: 100%;
                        text-align: center;
                        color: #393939;
                        font-family: "Roboto-Regular";
                        font-size: $font14;
                        img{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.loadCommentView {
    flex-direction: row;
    padding-top: 6;
    padding-bottom: 10;
    padding-right: 10;
    padding-left: 10;
    justify-content: center;
    margin-top: 10;
    .loadCommentIcon {
        height: 15;
        width: 15;
        margin-right: 10;
        margin-left: 6;
    }
    .loadComment {
        font-family: "SFProText-Semibold";
        font-size: 13;
        color: #555555;
    }
}

.dayTextStyle {
    font-family: "SFProText-Semibold" !important;
    text-align: center !important;
    color: #bbc8d8 !important;
    font-size: 12px !important;
    margin-top: 13px !important;
    margin-bottom: 17px !important;
}

.senderTextView {
    max-width: "71.66%";
    margin-top: 17px;
}

.receiverTextView {
    max-width: "71.66%";
    margin-top: 15px;
}

.msg-time {
    font-family: "Roboto-Medium" !important;
    font-size: 12px !important ;
    line-height: 14px !important;
    color: #bbc8d8 !important;
    text-align: right !important;
    margin-top: 7px !important;
}

.plr {
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.3);
    // max-height: 200px !important;
    margin-top: 0px !important;
}

.msg-attachment-img {
    max-width: 200px;
    max-height: 200px;
    border: 1px solid #ebeff3;
    border-radius: 10px 10px 10px 10px;
    overflow: hidden;
    margin-bottom: 5px;
    cursor: pointer;
    img {
        width: 200px;
        height: 200px;
    }
}

.msg-attachment-video {
    max-width: 200px;
    min-width: 200px;
    max-height: 200px;
    border: 1px solid #ebeff3;
    border-radius: 10px 10px 10px 10px;
    overflow: hidden;
    margin-bottom: 5px;
    cursor: pointer;
    .plr {
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.3);
        max-height: 200px !important;
        margin-top: 0px !important;
    }
}

.msg-attachment-img-preview {
    max-width: 80%;
    max-height: 80%;
    overflow: hidden;
    // img{
    //     width: 200px;
    //     height: 200px;
    // }
}
.appbar {
    background-color: $whiteColor !important;
}
.MuiBackdrop-root {
    z-index: 2000;
}
.video-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
}
.video-image {
    max-width: 200px;
    max-height: 200px;
    width: 200px;
    height: 200px;
    border: 1px solid #ebeff3;
    border-radius: 10px 10px 10px 10px;
    overflow: hidden;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.3);
}
.play-icon {
    overflow: hidden;
    position: absolute !important;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.8));
    padding: 0 !important;
}

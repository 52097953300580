@import '../../global.scss';

.notification-list-wrapper{
    width: 100%;
    height: 100%;
    .notification-heading{
        color: #202020;
        font-family: 'Roboto-Bold';
        font-size: $font16;
        line-height: 19px;
        padding: 20px 20px 0px 20px
    }
    .action-wrapper{
        padding: 10px 20px 10px 20px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #A8A8BC;
        .all-noti-read-text{
            font-family: 'Roboto-Medium';
            font-size: $font14;
            line-height: 16px;
            color: #00B5FD;
            cursor: pointer;
        }
        .clear-all-text{
            color: #00B5FD;
            font-family: 'Roboto-Medium';
            font-size: $font14;
            line-height: 16px;
            cursor: pointer;
        }
    }
    .list-container{
        height: calc(100% - 100px);
        overflow-y: auto;
        margin: 0px 10px 5px 20px;
        .list-item{
            display: flex;
            align-items: center;
            &:not(:first-child){
                margin-top: 15px;
            }
            cursor: pointer;
            .left-section{
                display: flex;
                align-items: center;
                flex: 8;
                .profile-pic-wrapper{
                    width: 60px;
                    height: 60px;
                    overflow: hidden;
                    border-radius: 50%;
                    .profile-pic{
                        width: 100%;
                        object-fit: cover;
                    }
                }
                .text-info-wrapper{
                    padding-left: 15px;
                    .user-name-text{
                        color: #A3A3A3;
                        font-family: 'Roboto-Medium';
                        font-size: $font14;
                        line-height: 16px;
                    }
                    .msg-text{
                        color: #A3A3A3;
                        font-family: 'Roboto-Regular';
                        font-size: $font13;
                        line-height: 15px;
                        padding-top: 5px;
                        padding-bottom: 2px;
                    }
                    .msg-time{
                        color: #A3A3A3;
                        font-family: 'Roboto-Regular';
                        font-size: $font12;
                        line-height: 14px;
                        text-align: left !important;
                    }
                    .unread-text{
                        color: $blackColor !important;
                    }
                    .unread-text-time{
                        color: #00B5FD !important;
                    }
                }
            }
            .right-section{
                flex: 2;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding-right: 10px;
                .unread-badge{
                    width: 15px;
                    height: 15px;
                    background-color: #00B5FD;
                    border-radius: 50%;
                }
            }
        }

    }
    .empty-container{
        height: calc(100% - 40px);
        display: flex;
        justify-content: center;
        align-items: center;
        .content{
            .empty-text{
                text-align: center;
                font-family: 'Roboto-Regular';
                font-size: $font14;
                line-height: 16px;
                color: #393939;
            }
        }
    }
    .load-more-text{
        font-family: 'Roboto-Medium';
        font-size: $font14;
        line-height: 16px;
        color: #A3A3A3;
        cursor: pointer;
    }
}
.sub-header {
  position: sticky;
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 10px 10px 0px 0px;
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  span {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    font-family: "Roboto-Medium";
    color: #333333;
  }
}
.feed-card {
  background: #ffffff;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin-top: 15px;
  &:last-child{
    margin-bottom: 15px;
  }
  .drawer-profile-img {
    height: auto;
    width: auto;
    max-height: 30px;
    max-width: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: relative;
  }

  .drawer-profile-img-div {
    height: 30px;
    width: 30px;
    min-width: 30px;
    background: #f1f1f1;
    border-radius: 30px;
    overflow: hidden;
    //    display: flex;
  }
}
.last-card-space{
  height: 15px;
}
.video-card {
  padding: 10px;
  border-radius: 10px;
  // width:100%;
  .video-card-header {
    display: flex;
    justify-content: space-between;
  }
  .profile-details {
    margin-top: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .user-details {
    margin-left: 10px;
  }
  .user-name {
    font-family: "Roboto-Medium";
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    color: #333333;
  }
  .user-role {
    display: flex;
    align-items: "center";
    font-family: "Roboto-Medium";
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: rgba(130, 130, 130, 0.83);
    text-align: left;
    padding-top: 3px;
    svg {
      margin-right: 5px;
    }
  }
  .feed-time {
    text-align: right;
  }
  .category-text {
    font-family: "Roboto-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #828282;
  }
  .category-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.empty-view {
  height: 77vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  border-radius: 10px 10px 0px 0px;
  margin-top: 15px;
  h6 {
    font-family: "Roboto-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #393939;
  }
}

.filter {
  margin-left: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.MuiMenu-paper {
  width: 385px;
  // height: 610px !important;
}
.list-header {
  justify-content: flex-start;
  display: flex;
  padding: 15px;
  font-family: "Roboto-Medium";
  font-size: 16px;
  color: #393939;
}

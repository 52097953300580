
.video-preview-wrapper{
    height: 100%;
    .header-view{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        .left-header-view{
            .close-icon{
                cursor: pointer;
            }
        }
    }
    .video-container{
        height: calc(100% - 47px);
        .plr {
            overflow: hidden;
            background-color: rgba(0,0,0,0.3);
            height: 100% !important;
            margin-top: 0px !important;
          }
    }
}
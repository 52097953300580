@import '../../../global.scss';

.change-pwd-modal{
  max-width: 385px !important;
  .modal-content{
    min-height: 294px !important;
    border-radius: 10px !important;
  }
  .modal-body{
    padding: 0px !important;
    .MuiFormControl-root{
      .MuiInputBase-input{
        padding-left: 10px !important;
      }
      .MuiInputLabel-shrink {
        transform: translate(-20px, -5px) scale(0.75) !important;
      }
      .MuiInputLabel-outlined {
        transform: translate(-20px, 20px) scale(1);
      }
    }
    .settings-list-container{
        padding-bottom: 20px;
        .setting-item{
            width: 100%;
            border-bottom: 1px solid $borderColor;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .item-name{
                padding: 13px 20px;
                color: $headingColor;
                font-family: "Roboto-Medium";
                font-size: 14px !important;
                font-weight: 500;
            }
            .item-action{
                padding: 6px 20px;
            }
        }
    }
  }
  .modal-footer{
    padding: 0px 20px 20px 20px !important;
    border-top: none;
  }
  .modal-custom-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px !important;
    border-bottom: 1px solid $borderColor;
    padding: 20px 20px 22px 20px !important;
    .heading-text{
      color: $headingColor;
      font-family: "Roboto-Medium";
      font-size: 14px !important;
      font-weight: 500;
      line-height: 16px;
    }
    .close-icon{
      cursor: pointer;
      display: flex;
    }
  }
}

.change-pwd-modal-header button{
  padding: 0;
  background: transparent;
  border: 0;
}
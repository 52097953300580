@import "../../global.scss";
@import "../../styles/variables.scss";

.common-auth-wrapper {
  .row {
    margin-left: 0;
    margin-right: 0;
  }
  .bg-image {
    background: #141414;
    background-size: cover;
    background-position: center; // 100%
    width: 100%;
    height: 99.99vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
      // position: absolute;
      // top: 39%;
      // left: 30%;
      background: url("../../assets/AuthScreen_Logo.svg") no-repeat;
      background-size: contain;
      background-position: 100% 100%;
      width: 235px;
      height: 135px;
    }
  }
  .common-reg-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      font-size: $font30;
      color: $headingColor;
      margin: 0px;
      font-family: "SFProText-Semibold";
      margin-bottom: 30px;
      // text-transform: uppercase;
    }
  }
  .form-wrapper {
    padding: 0px 110px;
    width: 100%;
    text-align: center;
  }
  .subtext {
    font-size: $font13;
    color: #929292;
    // margin: 0 0 28px;
    font-family: "Roboto-Medium";
    .number {
      color: #000000;
    }
  }
  .backtext {
    font-size: $font13;
    color: #231e20;
    // margin: 0 0 28px;
    font-family: "Roboto-Regular";
  }
  .text-item-top {
    margin-top: 29px;
  }
  .text-item {
    position: relative;
    // margin-bottom: 23px;
    .text-icon {
      position: absolute;
      left: 16px;
      top: 7px;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
    .text-field {
      position: relative;
      width: 100%;
    }
  }

  .email-field {
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .signup-button {
    margin-bottom: 22px;
    text-transform: capitalize !important;
  }

  .links {
    text-align: right;
    margin-bottom: 15px;
  }

  .password-field {
    margin-bottom: 15px;
  }

  .link-text {
    font-size: $font14;
    color: $primaryColor;
    // text-transform: uppercase;
    font-family: "Roboto-Medium";
    text-decoration: none;
    font-weight: 600;
  }

  .login-text {
    font-size: $font14;
    color: $primaryColor;
    // text-transform: uppercase;
    font-family: "Roboto-Regular";
    text-decoration: none;
    font-weight: 500;
  }

  .hide-pwd {
    background: url("../../assets/eye_closed.svg") no-repeat;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 7px;
    cursor: pointer;
  }

  .show-pwd {
    background: url("../../assets/eye_open.svg") no-repeat;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 7px;
    cursor: pointer;
  }

  .otp-container {
    justify-content: space-between;
    align-items: center;
    margin-right: 30px;
    margin-left: 30px;

    .otp-input {
      align-items: center;
      justify-content: center;
      font-size: $font15;
      color: $blackColor;
      font-family: "Roboto-Bold";
      width: 44px !important;
      height: 44px;
    }
  }
}

.countryCodeInput {
  flex: 1.8;
  margin-right: 10px;
  height: 40px;
  padding-bottom: 20px;
}

.MuiFormLabel-root.Mui-focused {
  background: $whiteColor !important;
  font-size: $font12 !important;
  color: $labelColor !important;
  padding: 0px 3px !important;
}

.MuiInputLabel-formControl {
  // left: 12px !important;
  top: -9px !important;
  font-size: $font13 !important;
  background: $whiteColor !important;
  font-size: $font12 !important;
  color: $labelColor !important;
  padding: 0px 3px !important;
  font-family: "Roboto-Regular" !important;
}

.MuiInputLabel-shrink {
  transform: translate(14px, 0px) scale(0.75) !important;
}

.MuiInputBase-input {
  padding: 8px 16px 12px 12px !important;
  font-size: $font13 !important;
  color: $blackColor !important;
  height: unset !important;
  font-family: "Roboto-Regular" !important;
  text-align: initial;
}

.text-field-disabled {
  color: #bdbdbd !important;
  background: #fcfcfe !important;
}

// .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
//   border: 1px solid $labelColor !important;
// }

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 1px solid #e0e0e0 !important;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: $dangerColor !important;
  top: -5px !important; // 0px
}
.MuiFormLabel-root.Mui-error {
  color: $dangerColor !important;
}
.MuiFormHelperText-root.Mui-error {
  margin-left: 0px !important;
}
.MuiSelect-select:focus {
  background: transparent !important;
}

// .MuiList-root {
//   height: auto !important;
// }

.role-dropdown {
  width: 100%;
  .dd-item {
    font-size: 13px !important;
    font-family: "Roboto-Medium" !important;
  }
}
.MuiPopover-paper {
  margin-top: 8px;
}

.profileImage {
  height: 86px;
  width: 86px;
  border-radius: 50%;
  background: #f8fafb;
  border: 1px solid #d0d1dc;
  box-sizing: border-box;
  object-fit: cover;
}

.cancel-btn {
  color: #00b5fd !important;
  @include Roboto-Medium;
  font-size: 12px !important;
  font-style: normal !important;
  letter-spacing: normal !important;
  line-height: normal !important;
}

.save-btn {
  background-color: #00b5fd !important;
  border-radius: 5px !important;
  @include Roboto-Medium;
  font-size: 12px !important;
  font-style: normal !important;
  letter-spacing: normal !important;
  line-height: normal !important;
  color: #ffffff !important;
  width: 90px !important;
  height: 30px !important;
}

.appbar {
  background-color: $whiteColor !important;
}

.terms-privacy-policy-container {
  // margin-top: -20px;
  margin-bottom: 20px;
}

.privacy-policy-text {
  font-size: 12px;
  font-weight: 400px;
  font-style: normal;
  line-height: 16px;
  color: #a8a8bc;
}

.terms-text {
  color: #00b5fd;
  cursor: pointer;
}

.divider {
  background-color: #a8a8bc;
  height: 1px;
  width: 70px;
}

.divider-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.or-text {
  color: #a8a8bc;
  font-size: 13px;
  line-height: 22px;
  font-weight: 500;
  font-style: normal;
}

.social-media-login-button {
  cursor: pointer;
}

.common-auth-wrapper .otp-container .otp-input:focus-visible {
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #000000;
  font-family: "Roboto-Bold";
  width: 44px !important;
  height: 44px;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  outline-color: #00b5fd;
}

.MuiFormLabel-root.Mui-focused {
  color: #00b5fd !important;
  font-family: "Roboto-Medium" !important;
}

@media (max-width: 800px) {
  .form-wrapper {
    padding: 0px 20px !important;
  }

  .common-auth-wrapper .common-reg-wrapper {
    height: 100vh;
  }

  .dropDownFont {
    font-size: 4vw !important;
  }
}

.player-wrapper-profile {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player-profile {
  position: absolute;
  top: 0;
  left: 0;
  video{
    border-radius: 10px;
  }
}
.react-player__preview{
border-radius: 10px;
}
@import '../../global.scss';

.empty-wrapper{
    >div{
      text-align: center;
    }
    
    p{
      margin-top: 30px;
      font-size: $font15;
      color: $labelColor;
      font-family: "Roboto-Regular";
      font-weight: 400;
    }

    .empty-action-btn{
      font-size: $font13;
      color: $whiteColor;
      font-family: "Roboto-Regular";
      font-weight: 500;
      border-radius: 18px;
      background-color: $emptyActionBtnColor;
      border: 1px solid $emptyActionBtnColor;
      padding: 8px 30px;
    }
  }
@import "../../styles/variables.scss";

.other-user-profile-container {
    display: grid;
    justify-content: center;
}

.user-profile-picture {
    width: 86px;
    height: 86px;
    border-radius: 43px;
    margin-top: 20px;
}

.other-user-name {
    font-family: "Roboto-Medium";
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    margin-top: 7px;
    text-align: center;
}

.other-user-role {
    font-family: "Roboto-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 15px;
    color: #898A8D;
    margin-top: 5px;
    text-align: center;
    margin-bottom: 20px;
}

.tag-name {
    color: #00B5FD;
    font-size: 10px;
    line-height: 12px;
    font-family: "Roboto-Regular";
    margin-left: 5px;
}

.tag-container {
    display: flex;
    margin-top: 19px;
    margin-left: 17px;
}

.chat-btn {
    position: fixed !important;
    right: 0;
    bottom: 120px;
    z-index: 10;
}
@import "../../styles/variables.scss";

.user-details-wrapper {
  .common-wrapper {
    // margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .page-header {
    background-color: #f9f9f9;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 10px 10px 0px 0px;
    height: 50px;
    display: flex;
    align-items: center;
    .title {
      color: #333333;
      @include Roboto-Medium;
      font-size: 15px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;
      text-align: left;
      padding: 15px 17px;
    }
  }
  .personal-info-wrapper {
    margin: 10px 0px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 10px;
    .section-header {
      background-color: #ebebeb;
      border: 1px solid #ebebeb;
      box-sizing: border-box;
      border-radius: 10px 10px 0px 0px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        color: #333333;
        @include Roboto-Medium;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        text-align: left;
        padding: 15px 17px;
      }
    }
    .title {
      color: #1a1a1a;
      @include Roboto-Bold;
      font-size: 17px;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;
      text-align: left;
      padding: 15px 17px;
    }
    .add-text {
      color: #333333;
      @include Roboto-Medium;
      font-size: 13px;
      font-weight: 500;
      padding: 17px;
    }
    .bio-text {
      color: #a8a8bc;
      @include Roboto-Medium;
      font-size: 13px;
      font-weight: 500;
      padding: 17px;
    }
    .business-subheading {
      color: #333333;
      @include Roboto-Medium;
      font-size: 14px;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;
      text-align: left;
      word-break: break-word;
    }
    .business-text {
      color: #a8a8bc;
      @include Roboto-Medium;
      font-size: 13px;
      font-weight: 500;
      padding: 10px;
      padding-left: 0;
      word-break: break-word;
    }
    .video-image-profile {
      width: 845px;
      height: 477px;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      margin: 17px;
    }
    .play-icon {
      position: absolute;
      filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.8));
      left: 50%;
    }
    .image-container {
      padding-top: 0 !important;
      margin: 18px;
    }
    .info-container {
      display: flex;
      padding-top: 15px;
      .profile-pic-section {
        padding: 15px 17px;
        .profile-pict {
          width: 150px;
          height: 150px;
          background-image: url("../../assets/default_image.svg");
          background-size: cover;
          img {
            width: 100%;
            height: 100%;
            margin-right: 0px;
            margin-right: 3px !important;
          }
        }
        .user-status {
          text-align: center;
          padding-top: 10px;
          @include Roboto-Regular;
          font-size: 13px;
          font-weight: 400;
        }
        .approve-text {
          color: #17b82c;
        }
        .pending-text {
          color: #ffa500;
        }
        .rejected-text {
          color: #a58b89;
        }
        .blocked-text {
          color: #ff1200;
        }
        .reported-text {
          color: #0f0643;
        }
      }
      .upload-image-row-div{
        width: 107px;
        height: 107px;
        position: relative;
        background: rgba(187, 200, 216, 0.1);
        border: 1px dashed rgba(168, 168, 188, 0.5);
        box-sizing: border-box;
        border-radius: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-right: 8px;
        // @media (max-width: 599px) {
        //   margin-top: 5px;
        // }
        .remove-icon {
          position: absolute;
          padding: 0;
          top: 2px;
          right: 2px;
        }
        .profile-icon {
          position: absolute;
          padding: 0;
          top: 2px;
          left: 2px;
        }
        .upload-text {
          color: #a8a8bc;
          @include Roboto-Medium;
          font-size: 13px;
          font-weight: 500;
        }
        .profile-img {
          width: 107px;
          height: 107px;
          background: #ffffff;
          border-radius: 10px;
          border: 0px;
          object-fit: cover;
        }
      }
      .image-row-div {
        width: 107px;
        height: 107px;
        position: relative;
        background: rgba(187, 200, 216, 0.1);
        box-sizing: border-box;
        border-radius: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-right: 8px;
        .remove-icon {
          position: absolute;
          padding: 0;
          top: 2px;
          right: 2px;
        }
        .profile-icon {
          position: absolute;
          padding: 0;
          top: 2px;
          left: 2px;
        }
        .upload-text {
          color: #a8a8bc;
          @include Roboto-Medium;
          font-size: 13px;
          font-weight: 500;
        }
        .profile-img {
          width: 107px;
          height: 107px;
          background: #ffffff;
          border-radius: 10px;
          border: 0px;
          object-fit: cover;
        }
      }
      .row-div {
        display: flex;
        justify-content: flex-start;
        padding-bottom: 8px;
        padding-left: 18px;
        .key-text {
          color: #333333;
          @include Roboto-Medium;
          font-size: 13px;
          font-weight: 500;
          min-width: 99px; //83785vw
        }
        .key-text-right {
          color: #333333;
          @include Roboto-Medium;
          font-size: 13px;
          font-weight: 500;
          min-width: 33px; //4.785
        }
        .value-text {
          color: #a8a8bc;
          @include Roboto-Medium;
          font-size: 13px;
          font-weight: 500;
          padding: 0px 20px 0px 22px;
          word-break: break-all;
          @media (max-width: 599px) {
            flex-grow: 1;
            text-align: end;
          }
        }
        .value-link {
          color: #0d6efd;
          @include Roboto-Medium;
          font-size: 13px;
          font-weight: 500;
          padding: 0px 20px 0px 22px;
          word-break: break-all;
          @media (max-width: 599px) {
            flex-grow: 1;
            text-align: end;
          }
        }
      }
      .social-section {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 7px;
        margin-bottom: 20px;
        .social-item {
          padding-right: 15px;
          a {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.text-field {
  width: 100%;
  padding-right: 10px !important;
  padding-bottom: 15px !important;
  color: #000000;
  @include Roboto-Regular;
  font-size: 13px;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  .MuiOutlinedInput-notchedOutline{
    height: 45px !important;
  }
}
.bio-text, .business-details-text{
  .MuiOutlinedInput-notchedOutline{
    height: unset !important;
  }
}
.disable-field{
  .MuiOutlinedInput-notchedOutline{
    height: 45px !important;
    // background-color: #fcfcfe !important;
  }
  .MuiInputBase-formControl{
    background-color: #fcfcfe !important;
    padding-right: 0px !important;
  }
}

.text-field-disabled {
  width: 100%;
  padding-right: 10px !important;
  padding-bottom: 15px !important;
  color: #bdbdbd !important;
  @include Roboto-Regular;
  font-size: 13px;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}

.cancel-btn {
  color: #00b5fd !important;
  @include Roboto-Medium;
  font-size: 12px !important;
  font-style: normal !important;
  letter-spacing: normal !important;
  line-height: normal !important;
  text-transform: capitalize !important;
}

.save-btn {
  background-color: #00b5fd !important;
  border-radius: 5px !important;
  @include Roboto-Medium;
  font-size: 12px !important;
  font-style: normal !important;
  letter-spacing: normal !important;
  line-height: normal !important;
  color: #ffffff !important;
  width: 90px !important;
  height: 30px !important;
  text-transform: capitalize !important;
}

.save-btn-preview {
  background-color: #00b5fd !important;
  border-radius: 5px !important;
  @include Roboto-Medium;
  font-size: 12px !important;
  font-style: normal !important;
  letter-spacing: normal !important;
  line-height: normal !important;
  color: #ffffff !important;
  width: 120 !important;
  height: 30px !important;
  text-transform: capitalize !important;
}

.appbar {
  background-color: $whiteColor !important;
}

.bio-dialog {
  width: 450px;
  padding-left: 12px !important;
  padding-bottom: 24px !important;
  padding-right: 12px !important;
  @media (max-width: 599px) {
    width: 83vw;
  }
  .MuiOutlinedInput-multiline {
    padding: 0px;
  }
  .MuiDialogContent-root {
    padding: 0px !important;
  }
  
}

.MuiPaper-root.MuiDialog-paper {
  border-radius: 8px;
}

.cross-icon {
  position: absolute;
  cursor: pointer;
  top: 24px;
  right: 24px;
  width: 12px;
}

.MuiDialogContent-root:first-child {
  padding-top: 0px !important;
}

.video-div {
  height: 35.452vw;
  margin: 10px;
  margin-top: 0;
  @media (max-width: 599px) {
    height: 50.452vw;
  }
}

.video-dialog {
  width: 740px;
  border-radius: 10px;
  @media (max-width: 599px) {
    width: 100%;
  }
  .MuiOutlinedInput-multiline {
    padding: 0;
  }
  .image-row-div {
    height: 380px;
    position: relative;
    background: rgba(187, 200, 216, 0.1);
    border: 1px dashed rgba(168, 168, 188, 0.5);
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 8px;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 599px) {
      height: 55.625vw;
    width: 65.625vw;
    }
    .remove-icon {
      position: absolute;
      padding: 0;
      top: 2px;
      right: 2px;
    }
  }
}

.video-header {
  font-family: "Roboto-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.MuiButton-contained {
  box-shadow: none !important;
}

.profile-img-preview {
  width: 100%;
}
.MuiMenu-paper {
  width: 268px !important;
}
.MuiMenuItem-root {
  font-family: "Roboto-Regular" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}
.MuiListItem-button:hover {
  background-color: #eff8fe !important;
}
.MuiListItem-root:hover {
  background-color: none !important;
}
.MuiListItem-root.Mui-selected {
  color: #00b5fd;
  background-color: #ffffff !important;
}
